<template>
  <div class="container">
    <form @submit.prevent="submitForm" class="needs-validation" novalidate>
      <div class="mb-3">
        <b-form-group label="Nama Level" label-for="Level">
          <b-form-input v-model="formData.nama_level" id="Level" readonly></b-form-input>
        </b-form-group>
      </div>
      <div class="mb-3">
        <b-form-group label="Timer" label-for="timer">
          <b-form-input v-model="formData.timer" id="timer"></b-form-input>
        </b-form-group>
      </div>
      <div class="mb-3">
        <b-form-group label="Output Soal PG" label-for="output_soal_pg">
          <b-form-input v-model="formData.output_soal_pg" :readonly="level_prof == 3 || level_prof == 4" id="output_soal_pg"></b-form-input>
        </b-form-group>
      </div>
      <div class="mb-3">
        <b-form-group label="Output Soal BS" label-for="output_soal_bs">
          <b-form-input v-model="formData.output_soal_bs" id="output_soal_bs" :readonly="level_prof == 3 || level_prof == 4 || level_prof == 1"></b-form-input>
        </b-form-group>
      </div>
      <div class="mb-3">
        <b-form-group label="Output Soal MJ" label-for="output_soal_mj">
          <b-form-input v-model="formData.output_soal_mj" id="output_soal_mj" :readonly="level_prof == 3 || level_prof == 4 || level_prof == 1"></b-form-input>
        </b-form-group>
      </div>
      <div class="mb-3">
        <b-form-group label="Output Soal PGSK" label-for="output_soal_pgsk">
          <b-form-input v-model="formData.output_soal_pgsk" id="output_soal_pgsk" :readonly="level_prof == 2 || level_prof == 4 || level_prof == 1"></b-form-input>
        </b-form-group>
      </div>
      <div class="mb-3">
        <b-form-group label="Output Soal Text" label-for="output_soal_text">
          <b-form-input v-model="formData.output_soal_text" id="output_soal_text" :readonly="level_prof == 2 || level_prof == 3 || level_prof == 1"></b-form-input>
        </b-form-group>
      </div>
      <div class="mb-3">
        <b-form-group label="Output Soal BSSK" label-for="output_soal_text">
          <b-form-input v-model="formData.output_soal_bssk" id="output_soal_text " :readonly="level_prof == 2 || level_prof == 4 || level_prof == 1"></b-form-input>
        </b-form-group>
      </div>
      <div class="mb-3">
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  props: ["idjadwal_timer", "level"],
  data() {
    return {
      formData: {
        nama_level: "",
        timer: "",
        output_soal_pg: "",
        output_soal_bs: "",
        output_soal_mj: "",
        output_soal_pgsk: "",
        output_soal_text: "",
        output_soal_bssk: "",
      },
      idjadwal: this.$route.params.id,
      level_prof: "",
    };
  },
  mounted() {
    this.getDataById();
    this.level_prof = this.level;
    console.log(this.level_prof);
  },
  methods: {
    shouldDisableOutputSoalPG() {
      // Enable the field if the level is 1 or 2, otherwise disable it
      return this.level_prof !== 1 && this.level_prof !== 2;
    },
    submitForm() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      const authToken = localStorage.access_token;
      let url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pelaksanaan/" + this.idjadwal + "/setup-jadwal/jadwal-timer/" + this.idjadwal_timer;
      axios
        .put(url, this.formData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Sukses Mengubah Konfigurasi",
            timer: 3000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              window.location.reload();
            }
          });
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error.response.data);
        });
    },
    getDataById() {
      let url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/jadwal-pelaksanaan/" + this.idjadwal + "/setup-jadwal/jadwal-timer/" + this.idjadwal_timer;

      let self = this;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            var data = response_data_fix;
            self.formData.nama_level = data.level_kompetensi.nama_level;
            self.formData.timer = data.timer;
            self.formData.output_soal_pg = data.output_soal_pg;
            self.formData.output_soal_bs = data.output_soal_bs;
            self.formData.output_soal_mj = data.output_soal_mj;
            self.formData.output_soal_pgsk = data.output_soal_pgsk;
            self.formData.output_soal_text = data.output_soal_text;
            self.formData.output_soal_bssk = data.output_soal_bssk;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here if needed */
</style>
