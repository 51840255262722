<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      formData: {
        nama_batch: "",
        waktu_mulai: "",
        waktu_selesai: "",
      },
    };
  },
  emits: ['submitBatch'],
  methods: {
    submitForm() {
      if (!this.validateForm()) {
        return; // Do not submit the form if validation fails
      }
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      const authToken = localStorage.access_token;
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/batch`,
          this.formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              this.$emit("submitBatch");
            }
          });
        })
        .catch((error) => {
          Swal.close();

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data,
          });
        });
    },
    resetForm() {
      this.formData.nama_batch = "";
      this.formData.waktu_mulai = "";
      this.formData.waktu_selesai = "";
    },
    validateForm() {
      if (
        !this.formData.nama_batch ||
        !this.formData.waktu_mulai ||
        !this.formData.waktu_selesai
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan Lengkapi Data",
        });

        return false;
      }

      return true; // Form is valid
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here if needed */
</style>

<template>
  <div class="container">
    <form @submit.prevent="submitForm" class="needs-validation" novalidate>
      <div class="mb-3">
        <label for="name" class="form-label">Batch</label>
        <input
          v-model="formData.nama_batch"
          class="form-control"
          type="number"
          id="name"
          required
        />
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <label for="email" class="form-label">Waktu Mulai</label>
          <input
            v-model="formData.waktu_mulai"
            type="datetime-local"
            class="form-control"
            id="waktu_mulai"
            required
          />
          <div class="invalid-feedback">
            Please enter a valid email address.
          </div>
        </div>
        <div class="col-6">
          <label for="email" class="form-label">Waktu Selesai</label>
          <input
            v-model="formData.waktu_selesai"
            type="datetime-local"
            class="form-control"
            id="waktu_selesai"
            required
          />
          <div class="invalid-feedback">
            Please enter a valid email address.
          </div>
        </div>
      </div>

      <div class="mb-3">
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>
