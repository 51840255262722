<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import ModalAddBatch from "./modal-add-batch.vue";
import ModalEditBatch from "./modal-edit-batch.vue";
import permissionService from "../../../../../../helpers/permissionService";

export default {
  props: ["status_jadwal"],
  components: {
    Datatable,
    Pagination,
    ModalAddBatch,
    ModalEditBatch,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      {
        width: "100px",
        label: "Batch",
        name: "idjadwal",
      },
      {
        width: "auto",
        label: "Waktu Mulai",
        name: "waktu_mulai",
      },
      {
        width: "auto",
        label: "Waktu Selesai",
        name: "waktu_selesai",
      },
      {
        width: "auto",
        label: "Jumlah Kompetensi",
        name: "waktu_selesai",
      },
      {
        width: "auto",
        label: "Jumlah Peserta",
        name: "waktu_selesai",
      },

      {
        width: "auto",
        label: "Aksi",
        name: "-",
      },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Jadwal Pelaksanaan",

      showModalAdd: false,
      showModalEdit: false,
      idBatchEdit: 0,
      selectedBatch: {},
      selected_id: "ss",
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableData: {
        page: 0,
        per_page: 10,
        search: "",
        column: 0,
        dir: "asc",
        filter_status: "all", // untuk filtering data
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      isWriteTambah: 0,
      isReadTambah: 0,
      isWriteEdit: 0,
      isReadEdit: 0,
      isWriteSetup: 0,
      isReadSetup: 0,
      isWriteDelete: 0,
      isReadDelete: 0,
    };
  },
  mounted() {
    this.cekPermission("tambah", "Tambah Batch");
    this.cekPermission("edit", "Edit Batch");
    this.cekPermission("setup", "Setup Batch");
    this.cekPermission("delete", "Delete Batch");
    let self = this;
    self.getDataTable();
  },
  methods: {
    async cekPermission(type, permission) {
      const data = await permissionService.getHakAkses(permission);
      if (type == "tambah") {
        this.isWriteTambah = data.is_write;
        this.isReadTambah = data.is_read;
      } else if (type == "edit") {
        this.isWriteEdit = data.is_write;
        this.isReadEdit = data.is_read;
      } else if (type == "setup") {
        this.isWriteSetup = data.is_write;
        this.isReadSetup = data.is_read;
      } else if (type == "delete") {
        this.isWriteDelete = data.is_write;
        this.isReadDelete = data.is_read;
      }
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/batch/` +
              id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },

    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/batch`
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      axios({
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      })
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            console.log(response_data_fix);
            self.table_data = response_data_fix.data;
            self.configPagination(response_data_fix);
            self.currentTablePage = response_data_fix.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableData.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getDataTable();
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="card-title">Batch</div>
            </div>
            <div class="col-md-6" v-if="status_jadwal != 2">
              <div class="text-end">
                <button
                  v-on:click="showModalAdd = !showModalAdd"
                  class="btn btn-sm btn-success"
                  v-if="isWriteTambah == 1"
                >
                  <i class="fa fa-plus"></i> Tambah Batch
                </button>
              </div>
            </div>
            <!-- <div class="col-md-12 mb-2">
              <div class="row">
                <div class="col-md-4">
                  <table>
                    <tr>
                      <td>Show</td>
                      <td>
                        <select
                          class="form-control form-control-sm"
                          id="showpaginatetable"
                          style="width: 100%"
                          v-model="tableData.length"
                          @change="getDataTable()"
                        >
                          <option value="10" selected>10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                        </select>
                      </td>
                      <td>Entries</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-5">&nbsp;</div>
              </div>
            </div> -->
            <datatable
              :columns="columns"
              :sortKey="sortKey"
              :sortOrders="sortOrders"
              @sort="sortBy"
            >
              <tbody>
                <tr v-if="loadingTable">
                  <td colspan="6" class="text-center">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </td>
                </tr>
                <tr v-else-if="table_data == ''">
                  <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                </tr>
                <tr
                  v-else
                  v-for="(row_data, key_data) in table_data"
                  :key="key_data"
                >
                  <td class="text-center">
                    {{ row_data.nama_batch }}
                  </td>
                  <td>
                    {{ row_data.waktu_mulai }}
                  </td>
                  <td>
                    {{ row_data.waktu_selesai }}
                  </td>
                  <td>{{ row_data.status_peserta_count }}</td>
                  <td>
                    {{ row_data.jadwal_peserta_count }}
                  </td>
                  <td class="text-center">
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        class="btn btn-warning btn-sm"
                        v-if="status_jadwal != 2 && isWriteEdit == 1"
                        @click="
                          showModalEdit = true;
                          idBatchEdit = row_data.idjadwal_batch;
                          selectedBatch = row_data;
                        "
                      >
                        Edit
                      </button>
                      <!-- <router-link
                      class="btn btn-info btn-sm"
                      :to="{
                        name: 'detail-jadwal-batch',
                        params: {
                          idbatch: row_data.idjadwal_batch,
                        },
                      }"
                      v-if="status_jadwal == 1"
                    >
                      Detail Batch
                    </router-link> -->
                      <router-link
                        class="btn btn-info btn-sm"
                        :to="{
                          name: 'setup-jadwal-batch',
                          params: {
                            idbatch: row_data.idjadwal_batch,
                            isView: status_jadwal == 2,
                          },
                        }"
                        v-if="isWriteSetup == 1"
                      >
                        <span v-if="status_jadwal == 2"> Lihat Batch</span>
                        <span v-else>Setup Batch</span>
                      </router-link>
                      <button
                        class="btn btn-danger btn-sm"
                        v-if="
                          status_jadwal != 1 &&
                          status_jadwal != 2 &&
                          isWriteDelete == 1
                        "
                        v-on:click="deleteData(row_data.idjadwal_batch)"
                      >
                        Hapus
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </datatable>
          </div>
          <pagination :pagination="pagination" @to-page="toPage"></pagination>
        </div>
      </div>
    </div>
  </div>
  <b-modal v-model="showModalAdd" hide-footer title="Tambah Batch">
    <ModalAddBatch
      @submit-batch="
        showModalAdd = false;
        getDataTable();
      "
    />
  </b-modal>
  <b-modal v-model="showModalEdit" hide-footer title="Edit Batch">
    <ModalEditBatch
      :data-batch="selectedBatch"
      @submit-batch="
        showModalEdit = false;
        getDataTable();
      "
    />
  </b-modal>
</template>
