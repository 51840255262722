<script>
import Datatable from "@/router/layouts/table-datatable.vue";
// import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import ModalJadwalTimer from "./modal-edit-jadwal-timer.vue";

/**
 * Dashboard Component
 */
export default {
  props: ["status_jadwal"],
  components: {
    Datatable,
    // Pagination,
    ModalJadwalTimer,
    // ModalImportKompetensi,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      {
        width: "50px",
        label: "No",
        name: "idjadwal",
      },
      {
        width: "auto",
        label: "Nama Level",
        name: "Nama Level",
      },
      {
        width: "auto",
        label: "Timer",
        name: "Nama Level",
      },
      {
        width: "auto",
        label: "Output Soal PG",
        name: "Nama Level",
      },
      {
        width: "auto",
        label: "Output Soal BS",
        name: "keterangan",
      },
      {
        width: "auto",
        label: "Output Soal MJ",
        name: "keterangan",
      },
      {
        width: "auto",
        label: "Output Soal PG SK",
        name: "keterangan",
      },
      {
        width: "auto",
        label: "Output Soal BS SK",
        name: "keterangan",
      },
      {
        width: "auto",
        label: "Output Soal Esai",
        name: "keterangan",
      },
      {
        width: "auto",
        label: "Aksi",
        name: "-",
      },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Jadwal Pelaksanaan",

      showModalEditTimer: false,
      selected_id: "",
      selected_level: "",
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id_role", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableData: {
        draw: 0,
        length: 10,
        search: "",
        column: 0,
        dir: "asc",
        filter_status: "all", // untuk filtering data
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.getDataTable();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/jadwal-timer`
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            console.log(response_data_fix);
            self.table_data = response_data_fix.data;
            self.configPagination(response_data_fix);
            self.currentTablePage = response_data_fix.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="card-title">Konfigurasi Timer dan Jumlah Soal</div>
            </div>

<!--            <div class="col-md-12 mb-2">-->
<!--              <div class="row">-->
<!--                <div class="col-md-4">-->
<!--                  <table>-->
<!--                    <tr>-->
<!--                      <td>Show</td>-->
<!--                      <td>-->
<!--                        <select-->
<!--                          class="form-control form-control-sm"-->
<!--                          id="showpaginatetable"-->
<!--                          style="width: 100%"-->
<!--                          v-model="tableData.length"-->
<!--                          @change="getDataTable()"-->
<!--                        >-->
<!--                          <option value="10" selected>10</option>-->
<!--                          <option value="50">50</option>-->
<!--                          <option value="100">100</option>-->
<!--                          <option value="200">200</option>-->
<!--                          <option value="500">500</option>-->
<!--                        </select>-->
<!--                      </td>-->
<!--                      <td>Entries</td>-->
<!--                    </tr>-->
<!--                  </table>-->
<!--                </div>-->
<!--                <div class="col-md-5">&nbsp;</div>-->
<!--                <div class="col-md-3">-->
<!--                  <input-->
<!--                    type="text"-->
<!--                    class="form-control form-control-sm"-->
<!--                    id="searchTable"-->
<!--                    placeholder="Cari Data ..."-->
<!--                    v-model="tableData.search"-->
<!--                    @input="getDataTable()"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <datatable
              :columns="columns"
              :sortKey="sortKey"
              :sortOrders="sortOrders"
              @sort="sortBy"
            >
              <tbody>
                <tr v-if="loadingTable">
                  <td colspan="6" class="text-center">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </td>
                </tr>
                <tr v-else-if="table_data == ''">
                  <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                </tr>
                <tr
                  v-else
                  v-for="(row_data, key_data) in table_data"
                  :key="key_data"
                >
                  <td class="text-center">
                    <div v-if="key_data + 1 == 10">
                      {{ currentTablePage }}0.
                    </div>
                    <div v-else>
                      {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                      }}{{ key_data + 1 }}.
                    </div>
                  </td>
                  <td>
                    {{ row_data.level_kompetensi.nama_level }}
                  </td>
                  <td>
                    {{ row_data.timer ?? "0" }}
                  </td>
                  <td>
                    {{ row_data.output_soal_pg ?? "0" }}
                  </td>
                  <td>
                    {{ row_data.output_soal_bs ?? "0" }}
                  </td>
                  <td>
                    {{ row_data.output_soal_mj ?? "0" }}
                  </td>
                  <td>
                    {{ row_data.output_soal_pgsk ?? "0" }}
                  </td>
                  <td>
                    {{ row_data.output_soal_bssk ?? "0" }}
                  </td>
                  <td>
                    {{ row_data.output_soal_text ?? "0" }}
                  </td>
                  <td>
                    <button
                      v-if="status_jadwal != 2"
                      type="button"
                      class="btn btn-warning btn-sm"
                      v-on:click="
                        showModalEditTimer = !showModalEditTimer;
                        selected_id = row_data.idjadwal_timer;
                        selected_level = row_data.level_idlevel;
                      "
                    >
                      <i class="fa fa-edit"></i> Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </datatable>
          </div>
<!--          <pagination :pagination="pagination" @to-page="toPage"></pagination>-->
        </div>
      </div>
    </div>
  </div>
  <b-modal v-model="showModalEditTimer" hide-footer title="Edit Konfigurasi">
    <ModalJadwalTimer
      :idjadwal_timer="selected_id"
      v-if="showModalEditTimer"
      :level="selected_level"
    />
  </b-modal>
</template>
