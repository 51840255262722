<script>
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import ModalAddPenilai from "./modall-add-penilai.vue";

export default {
  props: ["status_jadwal"],
  components: {
    Datatable,
    Pagination,
    ModalAddPenilai,
  },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      {
        width: "50px",
        label: "No",
        name: "-",
      },
      {
        width: "auto",
        label: "NIP",
        name: "nip",
      },
      {
        width: "auto",
        label: "Nama",
        name: "nama",
      },
      {
        width: "auto",
        label: "Kompetensi",
        name: "kompetensi.nama_kompetensi",
      },
      {
        width: "100px",
        label: "Aksi",
        name: "-",
      },
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Jadwal Pelaksanaan",

      showModalAdd: false,
      selected_id: "ss",
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "idjadwal_penilai",
      sortOrders: sortOrders,
      currentTablePage: "",

      // parsing data ke table data
      tableData: {
        page: 0,
        per_page: 10,
        search: "",
        column: 0,
        dir: "desc",
        filter_status: "all", // untuk filtering data
      },

      // config untuk paginasi
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.getDataTable();
  },
  methods: {
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/jadwal-penilai/jadwal-penilai`
    ) {
      let self = this;
      self.loadingTable = true;

      axios({
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      })
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            console.log(response_data_fix);
            self.table_data = response_data_fix.data;
            self.configPagination(response_data_fix);
            self.currentTablePage = response_data_fix.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = key;
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getDataTable();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    deleteData(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/jadwal-penilai/` +
              id,
            data: {
              id: id,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="card-title">Penilai</div>
            </div>
            <div
              class="col-md-6"
              v-if="status_jadwal != 1 && status_jadwal != 2"
            >
              <div class="text-end">
                <button
                  v-on:click="showModalAdd = !showModalAdd"
                  class="btn btn-sm btn-success"
                >
                  <i class="fa fa-plus"></i> Tambah Penilai
                </button>
              </div>
            </div>
            <div class="col-md-12 mb-2">
              <div class="row">
                <div class="col-md-4">
                  <table>
                    <tr>
                      <td>Show</td>
                      <td>
                        <select
                          class="form-control form-control-sm"
                          id="showpaginatetable"
                          style="width: 100%"
                          v-model="tableData.per_page"
                          @change="getDataTable()"
                        >
                          <option value="10" selected>10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                        </select>
                      </td>
                      <td>Entries</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-5">&nbsp;</div>
                <div class="col-md-3">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="searchTable"
                    placeholder="Cari Data ..."
                    v-model="tableData.search"
                    @input="getDataTable()"
                  />
                </div>
              </div>
            </div>
            <datatable
              :columns="columns"
              :sortKey="sortKey"
              :sortOrders="sortOrders"
              @sort="sortBy"
            >
              <tbody>
                <tr v-if="loadingTable">
                  <td colspan="6" class="text-center">
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </td>
                </tr>
                <tr v-else-if="table_data == ''">
                  <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                </tr>
                <tr
                  v-else
                  v-for="(row_data, key_data) in table_data"
                  :key="key_data"
                >
                  <td class="text-center">
                    <div v-if="key_data + 1 == 10">
                      {{ currentTablePage }}0.
                    </div>
                    <div v-else>
                      {{ currentTablePage - 1 != 0 ? currentTablePage - 1 : ""
                      }}{{ key_data + 1 }}.
                    </div>
                  </td>
                  <td>
                    {{ row_data.nip }}
                  </td>
                  <td>
                    {{ row_data.nama }}
                  </td>
                  <td>
                    {{ row_data.kompetensi.nama_kompetensi }}
                  </td>
                  <td class="text-center">
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      v-on:click="deleteData(row_data.idjadwal_penilai)"
                    >
                      <i class="fa fa-trash"></i> Hapus
                    </button>
                  </td>
                </tr>
              </tbody>
            </datatable>
          </div>
          <pagination :pagination="pagination" @to-page="toPage"></pagination>
        </div>
      </div>
    </div>
  </div>
  <b-modal v-model="showModalAdd" hide-footer title="Tambah Penilai">
    <ModalAddPenilai />
  </b-modal>
</template>
