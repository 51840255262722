// apiService.js
import axios from "axios";
import Swal from "sweetalert2";

const apiService = {
  getDataById: async (id, tableData) => {
    const url = `${process.env.VUE_APP_BACKEND_URL_VERSION}master/jadwal-pelaksanaan/${id}`;

    const config = {
      method: "get",
      url: url,
      params: tableData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.access_token}`,
      },
    };

    try {
      const response = await axios(config);
      const responseData = response.data.data;

      if (response.data.meta.code === 200) {
        return responseData;
      } else {
        throw new Error(responseData.message);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });

      throw error;
    }
  },
};

export default apiService;
