<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import Kompetensi from './tab-kompetensi/all-kompetensi.vue';
import Batch from "./tab-batch/all-batch.vue";
import Penilai from "./tab-penilai/all-penilai.vue";
import KonfigurasiTimer from "./tab-konfigurasi-timer/all-jadwal-timer.vue";
import ApiService from "@/services/setup-jadwal/SetupJadwalService.js";
import Swal from "sweetalert2";
import axios from "axios";
import permissionService from "../../../../../helpers/permissionService";

export default {
  page: {
    title: "Penjadwalan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    // Kompetensi,
    Batch,
    Penilai,
    KonfigurasiTimer,
  },
  data() {
    return {
      dataJadwal: "",
      showResult: false,
      formData: {
        status_jadwal: 1,
      },
      linkCatalyst: "https://catalyst.pln.co.id/login",
      isWriteSetup: 0,
      isReadSetup: 0,
      isWriteCancel: 0,
      isReadCancel: 0,
      isWriteExport: 0,
      isReadExport: 0,
      // linkCatalyst: 'http://[::1]/midpln/catalyst/login',
    };
  },
  mounted() {
    this.getDataById();
    this.cekPermission("setup", "Setup Jadwal");
    this.cekPermission("cancel", "Batal Jadwal");
    this.cekPermission("exportPeserta", "Export Peserta");
  },
  methods: {
    async cekPermission(type, permission) {
      const data = await permissionService.getHakAkses(permission);
      if (data) {
        if (type == "setup") {
          this.isWriteSetup = data.is_write;
          this.isReadSetup = data.is_read;
        } else if (type == "cancel") {
          this.isWriteCancel = data.is_write;
          this.isReadCancel = data.is_read;
        } else if (type == "exportPeserta") {
          this.isWriteExport = data.is_write;
          this.isReadExport = data.is_read;
        }
      }
    },
    async getDataById() {
      const tableData = {};
      this.dataJadwal = await ApiService.getDataById(
        this.$route.params.id,
        tableData
      );
      this.showResult = this.dataJadwal?.is_petunjuk == 1 ? true : false;
    },
    async updateStatusJadwal(status = null) {
      const confirm = await Swal.fire({
        title: "Apakah Anda Yakin mengubah Status Jadwal ini ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Iya",
        cancelButtonColor: "#d33",
        cancelButtonText: "Tidak",
      }).then((result) => result.isConfirmed);
      if (!confirm) return;

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      const authToken = localStorage.access_token;
      let payload = this.formData;
      payload.status_jadwal = status;

      axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            `master/jadwal-pelaksanaan/update-status-jadwal/${this.$route.params.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Halaman akan dimuat ulang.",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              window.location.reload();
            }
          });
        })
        .catch((error) => {
          Swal.close();

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data,
          });
        });
    },
    simpanPengaturan() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      const authToken = localStorage.access_token;
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            `master/jadwal-pelaksanaan/simpan-pengaturan/${this.$route.params.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Halaman akan dimuat ulang.",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              window.location.reload();
            }
          });
        })
        .catch((error) => {
          Swal.close();

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data,
          });
        });
    },
    async copyLink() {
      let text = this.linkCatalyst + "?seb=" + this.dataJadwal?.token_jadwal;
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      alert("copied!");
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    changeIsPetunjuk(val) {
      let label = val ? "menampilkan" : "menyembunyikan";
      Swal.fire({
        icon: "question",
        title: "Apakah Anda yakin " + label + " prosentase hasil ?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let valParam = val ? 1 : 0;
          await axios({
            method: "put",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/jadwal-pelaksanaan/" +
              this.$route.params.id +
              "/changeShowResult/" +
              valParam,
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          })
            .then((response) => {
              console.log(response);
              this.showResult = val;
              this.dataJadwal.is_petunjuk = val == true ? 1 : 0;
            })
            .catch((err) => {
              console.log(err);
              this.showResult = !val;
              this.dataJadwal.is_petunjuk = val != true ? 1 : 0;
            });
        } else {
          this.showResult = !val;
          this.dataJadwal.is_petunjuk = val != true ? 1 : 0;
        }
      });
    },
    exportExcel() {
      window.open(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "export-excel/peserta-jadwal-pelaksanaan/" +
          this.$route.params.id
      );
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <router-link
          class="btn btn-danger btn-sm"
          :to="{ name: 'all-jadwal-pelaksanaan' }"
        >
          Kembali
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4>Setup Jadwal</h4>
            <table border="0" class="mb-2">
              <tr class="mb-2">
                <td><b>ID Jadwal</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ dataJadwal.idjadwal }}</td>
              </tr>
              <tr class="mb-2">
                <td><b>Kode Tes</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ dataJadwal.kode_tes }}</td>
              </tr>
              <tr class="mb-2">
                <td><b>Pelaksanaan</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ dataJadwal.jadwal_pelaksanaan?.pelaksanaan || "-" }}</td>
              </tr>
              <tr class="mb-2">
                <td><b>Tanggal Mulai</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ dataJadwal.tanggal_mulai }}</td>
              </tr>
              <tr class="mb-2">
                <td><b>Tanggal Selesai</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>{{ dataJadwal.tanggal_selesai }}</td>
              </tr>
              <tr>
                <td><b>Keterangan</b></td>
                <td>:</td>
                <td>{{ dataJadwal.keterangan }}</td>
              </tr>
              <tr class="mb-2">
                <td><b>Status</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>
                  <div v-if="dataJadwal?.status_jadwal == 0">
                    <div
                      class="badge badge-pill badge-soft-danger font-size-12"
                    >
                      Batal
                    </div>
                  </div>
                  <div v-else-if="dataJadwal?.status_jadwal == 1">
                    <div class="badge badge-pill badge-soft-info font-size-12">
                      Aktif
                    </div>
                  </div>
                  <div v-else-if="dataJadwal?.status_jadwal == 2">
                    <div
                      class="badge badge-pill badge-soft-success font-size-12"
                    >
                      Selesai
                    </div>
                  </div>
                  <div v-else-if="dataJadwal?.status_jadwal == 3">
                    <div
                      class="badge badge-pill badge-soft-warning font-size-12"
                    >
                      Draft
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                class="mb-2"
                v-if="
                  dataJadwal?.status_jadwal == 1 ||
                  dataJadwal?.status_jadwal == 2
                "
              >
                <td><b>Link</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td width="75%">
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      :value="linkCatalyst + '?seb=' + dataJadwal?.token_jadwal"
                      disabled
                      class="form-control form-control-sm"
                      aria-describedby="basic-addon2"
                    />
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      id="button-addon2"
                      @click="copyLink()"
                    >
                      <i class="fa fa-copy"></i>
                    </button>
                  </div>
                </td>
              </tr>
              <tr class="mb-2" v-if="dataJadwal?.status_jadwal == 1">
                <td><b>Tampikan Hasil</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      v-model="showResult"
                      :checked="dataJadwal?.is_petunjuk == 1"
                      @change="changeIsPetunjuk(showResult)"
                    />
                  </div>
                </td>
              </tr>
              <tr
                class="mb-2"
                v-if="
                  dataJadwal?.status_jadwal == 1 ||
                  dataJadwal?.status_jadwal == 2
                "
              >
                <td><b>Password Peserta</b></td>
                <td>&nbsp;:&nbsp;</td>
                <td>
                  {{ dataJadwal.password_peserta }}
                </td>
              </tr>
            </table>
            <div class="mb-3 d-flex justify-content-between">
              <div class="d-flex gap-2">
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="updateStatusJadwal(1)"
                  v-if="
                    dataJadwal.status_jadwal != 1 &&
                    dataJadwal.status_jadwal != 2 &&
                    isWriteSetup == 1
                  "
                >
                  <i class="fa fa-check me-1"></i>
                  Set Aktif Jadwal
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="simpanPengaturan"
                  v-if="dataJadwal.status_jadwal == 1 && isWriteSetup == 1"
                >
                  <i class="fa fa-save me-1"></i>
                  Simpan Pengaturan
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="updateStatusJadwal(0)"
                  v-if="dataJadwal.status_jadwal == 1 && isWriteCancel == 1"
                >
                  <i class="fa fa-times me-1"></i>
                  Batalkan Jadwal
                </button>
              </div>
              <div>
                <button
                  class="btn btn-sm btn-success mx-1"
                  @click="exportExcel()"
                  v-if="isWriteExport == 1"
                >
                  <i class="fa fa-file-excel me-1"></i> Export Excel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div>
          <b-card no-body>
            <b-tabs pills card>
              <!-- <b-tab title="Kompetensi" active
               ><b-card-text>
                 <Kompetensi
                   :status_jadwal="dataJadwal.status_jadwal"
                 /> </b-card-text
             ></b-tab> -->
              <b-tab title="Batch"
                ><b-card-text>
                  <Batch
                    :status_jadwal="dataJadwal.status_jadwal"
                  /> </b-card-text
              ></b-tab>
              <b-tab title="Penilai"
                ><b-card-text>
                  <Penilai
                    :status_jadwal="dataJadwal.status_jadwal"
                  /> </b-card-text
              ></b-tab>
              <b-tab title="Konfigurasi Timer dan Jumlah Soal"
                ><b-card-text>
                  <KonfigurasiTimer
                    :status_jadwal="dataJadwal.status_jadwal"
                  /> </b-card-text
              ></b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </div>
  </Layout>
</template>
