<script>
import axios from "axios";
import Swal from "sweetalert2";
// import multiselect from "vue-multiselect";
export default {
  components: {},
  data() {
    return {
      formData: {
        peserta: "",
        idjadwal: this.$route.params.id,
        kompetensi: "",
      },
      id: this.$route.params.id,
      option_peserta: [],
      option_kompetensi: [],
    };
  },
  mounted() {
    this.getJabatan();
  },
  methods: {
    submitForm() {
      if (!this.validateForm()) {
        return; // Do not submit the form if validation fails
      }
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      const authToken = localStorage.access_token;
      const payload = { ...this.formData };
      payload.kompetensi = payload.kompetensi?.idkompetensi;
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            `master/jadwal-pelaksanaan/${this.$route.params.id}/setup-jadwal/jadwal-penilai`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Halaman akan dimuat ulang.",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              window.location.reload();
            }
          });
        })
        .catch((error) => {
          Swal.close();

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.data,
          });
        });
    },
    resetForm() {
      this.formData.nama_batch = "";
      this.formData.waktu_mulai = "";
      this.formData.waktu_selesai = "";
    },
    validateForm() {
      if (this.formData.peserta.length < 1) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Silahkan Pilih Peserta",
        });

        return false;
      }

      return true; // Form is valid
    },

    onSearchPeserta(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPeserta(loading, search, this);
      }
    },
    searchPeserta(loading, search) {
      const authToken = localStorage.access_token;
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL_VERSION}get-user-cermat-penilai?search=${search}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.option_peserta = response.data.data;
          this.option_peserta.map(function (x) {
            return (x.item_data = x.name + " " + x.nip + " ");
          });
          loading(false);
        })
        .catch(() => {});
      console.log();
      console.log(search);
    },

    getJabatan(loading, search) {
      let self = this;
      const authToken = localStorage.access_token;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/jadwal-pelaksanaan/get-kompetensi-jadwal/" +
            self.id,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.option_kompetensi = response.data.data;
          loading(false);
        })
        .catch(() => {});
      console.log();
      console.log(search);
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here if needed */
</style>

<template>
  <div class="container">
    <form @submit.prevent="submitForm" class="needs-validation" novalidate>
      <div class="mb-3">
        <label for="email" class="form-label">Cari Penilai</label>
        <v-select
          :options="option_peserta"
          label="item_data"
          @search="onSearchPeserta"
          placeholder="Cari Peserta"
          v-model="formData.peserta"
        >
          <template #selected-option="{ name, nip }">
            {{ name }} - {{ nip }}
          </template>
          <template v-slot:no-options> Cari Penilai </template>
          <template v-slot:option="option">
            <div class="d-center">{{ option.name }} - {{ option.nip }}</div>
          </template>
        </v-select>
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Kompetensi</label>
        <v-select
          :options="option_kompetensi"
          v-model="formData.kompetensi"
          placeholder="Pilih Kompetensi"
        >
          <template #selected-option="{ data, nama_kompetensi }">
            <div style="display: flex; align-items: baseline">
              <span>{{ data }}{{ nama_kompetensi }}</span>
            </div>
          </template>
          <template v-slot:no-options> Cari Kompetensi </template>
          <template v-slot:option="option">
            <div class="d-center">{{ option.nama_kompetensi }}</div>
          </template>
        </v-select>
      </div>
      <div class="mb-3">
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </form>
  </div>
</template>
